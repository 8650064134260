import { redirect, useLoaderData, type ActionFunctionArgs, type LoaderFunctionArgs } from 'react-router-dom'
import { Divider, Stack, Typography } from '@mui/material'
import { formatDate, getPublicStylepage, parseFormData, type UseLoaderData } from '@trinity/utils'
import { ImageCardList } from '@trinity/components'

export async function loader({ params }: LoaderFunctionArgs) {
  if (!params.uuid) throw new Error('No stylepage id provided')

  const stylepage = await getPublicStylepage(params.uuid)

  return { ...stylepage }
}

export async function action({ request }: ActionFunctionArgs) {
  const params = await parseFormData(request)

  if (params.intent === 'edit') {
    throw redirect(`${params.id}`)
  }

  return null
}

export function PublicStylepage() {
  const { data: stylepage, error, message } = useLoaderData() as UseLoaderData<typeof loader>

  if (error || !stylepage) {
    return <Typography>{message}</Typography>
  }

  const items = stylepage.items.map(item => ({
    key: item.id,
    image: item.image,
    inStock: item.fabric.stockStatus.t2iD?.inStock,
    dividerText: item.baseModel.description,
    primaryText: `${item.fabric.trinityNumber} (${item.fabric.position})`,
    secondaryText: item.fabric.description,
    hoverItems: [
      ['Last Updated', formatDate(item.updatedAt)],
      item.lining ? ['Lining', item.lining.trinityNumber] : [],
      item.button ? ['Button', item.button.description] : [],
      item.thread ? ['Thread', item.thread.description] : [],
      item.ribknit ? ['Ribknit', item.ribknit.description] : [],
      item.zipper ? ['Zipper', item.zipper.description] : [],
    ],
    item,
  }))

  return (
    <Stack spacing={4} px={6} pt={8}>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        spacing={2}
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant='h2'>{stylepage.name}</Typography>
        <Typography variant='body3' sx={{ fontStyle: 'italic' }}>
          Last Edited: {formatDate(stylepage.updatedAt)}
        </Typography>
      </Stack>
      <Divider />
      <Typography variant='h3'>Items</Typography>
      <ImageCardList items={items} actions={['edit']}>
        {item => (
          <>
            <input type='hidden' name='id' value={item.id} />
            <input type='hidden' name='baseModelId' value={item.baseModel.id} />
            <input type='hidden' name='fabricId' value={item.fabric?.id} />
            <input type='hidden' name='garmentType' value={item.garmentType} />
            <input type='hidden' name='imageUri' value={item.image} />
            <input type='hidden' name='liningId' value={item.lining?.id} />
            <input type='hidden' name='buttonId' value={item.button?.id} />
            <input type='hidden' name='threadId' value={item.thread?.id} />
            <input type='hidden' name='ribknitId' value={item.ribknit?.id} />
            <input type='hidden' name='zipperId' value={item.zipper?.id} />
          </>
        )}
      </ImageCardList>
    </Stack>
  )
}
