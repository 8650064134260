import { useState } from 'react'
import { useLoaderData, type LoaderFunctionArgs, useRouteLoaderData, useNavigate } from 'react-router-dom'
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { ZoomIn } from '@mui/icons-material'
import { Dialog, ImageNotFound, MaterialCard, useWindowSize } from '@trinity/components'
import { ALL_GARMENTS, capitalCase, type UseLoaderData } from '@trinity/utils'
import { loader as publicStylepageLoader } from './stylepages.$uuid'

export function loader({ params }: LoaderFunctionArgs) {
  if (!params.rackId) throw new Error('No rack id provided')

  return { rackId: Number(params.rackId) }
}

export function StylepageItem() {
  const { rackId } = useLoaderData() as UseLoaderData<typeof loader>
  const { data: stylepage } = useRouteLoaderData('publicStylepage') as UseLoaderData<typeof publicStylepageLoader>
  const navigate = useNavigate()
  const [isZoomed, setIsZoomed] = useState(false)

  const { height, width } = useWindowSize()
  const theme = useTheme()
  const onMobile = useMediaQuery(theme.breakpoints.between('mobile', 'tablet'))
  const onTablet = useMediaQuery(theme.breakpoints.only('tablet'))
  const onLaptop = useMediaQuery(theme.breakpoints.only('laptop'))
  const isMobile = onMobile || onTablet
  const windowSize = height - 345

  if (!stylepage) throw new Error('Stylepage not found')

  const stylepageItem = stylepage.items.find(item => item.id === rackId)
  const garmentType = ALL_GARMENTS.find(type => type.bitmask === stylepageItem?.garmentType)

  if (!stylepageItem || !garmentType) throw new Error('Stylepage item not found')

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 8, laptop: 0 }} justifyContent='flex-end'>
      <Stack
        bgcolor='neutral.extraLight'
        position={{ mobile: 'absolute' }}
        left={0}
        bottom={{ mobile: 'auto', laptop: 0 }}
        top={{ mobile: 80, laptop: 90 }}
        right={{ mobile: 0, laptop: '50%' }}
        pt={{ mobile: 8, laptop: 0 }}
      >
        <IconButton disableRipple onClick={() => setIsZoomed(true)} sx={{ alignSelf: 'flex-start' }}>
          <ZoomIn color='primary' />
        </IconButton>
        <Dialog
          fullWidth
          open={isZoomed}
          onClose={() => setIsZoomed(false)}
          PaperProps={{ sx: { maxWidth: '95%', overflow: 'hidden' } }}
        >
          <Box height={height} width={width - 100} overflow='scroll'>
            <img
              src={stylepageItem.image.replace('width=1000', 'width=2000')}
              alt={`${stylepageItem.fabric.description} ${garmentType.formalName}`}
              width={2000}
            />
          </Box>
        </Dialog>
        <Stack alignItems='center' justifyContent='center' height={1}>
          <img
            src={stylepageItem.image}
            alt={`${stylepageItem.fabric.description} ${garmentType.formalName}`}
            width={isMobile ? 300 : onLaptop ? 500 : 600}
          />
        </Stack>
      </Stack>
      <Stack spacing={4} px={{ laptop: 4 }} pt={{ mobile: 55, laptop: 8 }} width={{ laptop: 1 / 2 }}>
        <Typography variant='h3' sx={{ pl: { mobile: 4, laptop: 0 } }}>
          {`${stylepageItem.fabric.trinityNumber} (${stylepageItem.fabric.position})`}
        </Typography>
        <Typography sx={{ pl: { mobile: 4, laptop: 0 } }}>{stylepageItem.fabric.description}</Typography>
        <Divider sx={{ width: 1 }} />
        <Stack
          height={isMobile ? 'auto' : windowSize}
          sx={isMobile ? {} : { scrollbarWidth: 'thin', overflowY: 'scroll', overflowX: 'hidden' }}
        >
          <StylepageItemMaterials stylepageItem={stylepageItem} garmentType={garmentType} />
          <Button onClick={() => navigate(-1)} sx={{ width: 'fit-content', alignSelf: 'center', borderRadius: 6 }}>
            Back
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

function StylepageItemMaterials({
  stylepageItem,
  garmentType,
}: {
  stylepageItem: TrinityAPI.VisuzlierRackType
  garmentType: TrinityAPI.GarmentTypesType
}) {
  return (
    <Stack spacing={4} pb={8}>
      <Stack
        direction='row'
        spacing={4}
        alignItems='center'
        p={2}
        mx={{ laptop: 2 }}
        sx={{ boxShadow: theme => theme.elevation.button, textDecoration: 'none' }}
      >
        <img src={garmentType.image ?? ImageNotFound} alt={garmentType.formalName} height={85} width={65} />
        <Stack>
          <Typography variant='h6'>Garment Type</Typography>
          <Typography variant='smallBody1'>
            {`${garmentType.formalName} | ${stylepageItem.baseModel.description}`}
          </Typography>
        </Stack>
      </Stack>
      <Grid container spacing={2} justifyContent={{ mobile: 'center', desktop: 'flex-start' }}>
        {validMaterials.map(material => (
          <Material key={material} material={material} stylepageItem={stylepageItem} />
        ))}
      </Grid>
    </Stack>
  )
}

function Material({
  material,
  stylepageItem,
}: {
  material: (typeof validMaterials)[number]
  stylepageItem: TrinityAPI.VisuzlierRackType
}) {
  const materialType = stylepageItem[material]
  if (!materialType) return null
  return (
    <Grid>
      <MaterialCard
        headerText={capitalCase(material)}
        subText={isFabric(materialType) ? materialType.trinityNumber : materialType.description}
        image={materialType.image}
      />
    </Grid>
  )
}

const validMaterials = ['fabric', 'lining', 'button', 'thread', 'ribknit', 'zipper'] as const
const isFabric = (material: Record<string, unknown> | null): material is { trinityNumber: string } => {
  if (!material) return false

  return 'trinityNumber' in material
}
