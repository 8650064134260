import { Form } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'

// export async function loader({ request }: LoaderFunctionArgs) {
//   return null
// }

export async function action() {
  const sample = await fetch(
    'http://localhost:3000/v1/visualizer_stylepages/shared/a832bcae-da56-442c-8626-e244bef3f3f1',
    { method: 'GET' },
  )
  // eslint-disable-next-line no-console
  console.log(sample)
  return null
}

export function Sample() {
  // const actionData = useActionData() as UseActionData<typeof action>

  return (
    <Stack spacing={4}>
      <Typography variant='h1'>My Finger... is on the button</Typography>
      <Form method='POST'>
        <Button type='submit'>Push the Button</Button>
      </Form>
    </Stack>
  )
}
